/* 侧边栏个人信息卡片动态渐变色 */
#aside-content > .card-widget.card-info {
    background: linear-gradient(
      -45deg,
      #e8d8b9,
      #eccec5,
      #a3e9eb,
      #bdbdf0,
      #eec1ea
    );
    box-shadow: 0 0 5px rgb(66, 68, 68);
    position: relative;
    background-size: 400% 400%;
    -webkit-animation: Gradient 10s ease infinite;
    -moz-animation: Gradient 10s ease infinite;
    animation: Gradient 10s ease infinite !important;
  }
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* 黑夜模式适配 */
  [data-theme="dark"] #aside-content > .card-widget.card-info {
    background: #191919ee;
  }
  
  /* 个人信息Follow me按钮 */
  #aside-content > .card-widget.card-info > #card-info-btn {
    background-color: #3eb8be;
    border-radius: 8px;
  }